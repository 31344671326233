
import { mapGetters } from 'vuex'
export default {
    name: 'FloatingIcons',
    props: {
        icons: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        messageFab: false,
        transition: 'slide-x-reverse-transition',
    }),
    computed: {
        ...mapGetters({
            rightFloatingMessageIcon: 'cms/rightFloatingMessageIcon',
            agentFloatingMessageIcon:
                'themes/agent-floating-icon/agentFloatingSupportIcon',
            maintenance: 'themes/settings/maintenance',
        }),
    },
    created() {
        this.$nuxt.$on('@toggleAgent', (val) => {
            this.messageFab = val
        })
    },
    destroyed() {
        this.$nuxt.$off('@toggleAgent')
    },
    methods: {
        openNewWindow(link) {
            if (!link) return
            window.open(
                !link.toLowerCase().startsWith('http')
                    ? 'http://' + link
                    : link,
                'Google',
                'width=550,height=600'
            )
        },
    },
}
