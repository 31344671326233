
import fingerprint from '~/mixins/fingerprint'
import ReCaptcha from '~/mixins/recaptcha'
export default {
    mixins: [fingerprint, ReCaptcha],
    props: {
        toggle: {
            type: Function,
            default: () => {},
        },
        // to check if this component is called by Navbar or Login Page
        loginPage: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loginType: 'phone',
            currentPage: 'login',
            forgetPasswordType: 'contact', // [phone, email, contact]
            form: {
                player_id: '',
                phone: '',
                password: '',
                reg_fingerprint: '',
            },
            valid: true,
            loading: false,
            showPassword: false,
            rememberMe: true,
            country: '',
        }
    },
    created() {
        this.changeLoginType(null)
    },
    async mounted() {
        const username = this.$cookie.get('un')
        const phoneNumber = this.$cookie.get('pn')
        const loginOption = this.$cookie.get('login_option')
        if (username) this.form.player_id = username
        if (phoneNumber) this.form.phone = phoneNumber
        if (loginOption && ['phone', 'username'].includes(loginOption))
            this.loginType = loginOption
        this.form.reg_fingerprint = await this.visitorId()
        const countryCode = this.$cookie?.get('country_code')

        if (this.isInternational) {
            this.country =
                countryCode === 'GO'
                    ? this?.$cookie?.get('global_reg')
                    : countryCode
        } else {
            this.country = 'LA'
        }
    },
    methods: {
        async login() {
            this.$cookie.set('login_option', this.loginType)

            this.loading = true

            await this.$refs.form.validate()

            if (this.valid) {
                try {
                    if (this.loginType === 'username') {
                        delete this.form.phone
                    } else if (this.loginType === 'phone') {
                        delete this.form.player_id
                    } else {
                        return this.$toast.success('Wrong login option')
                    }
                    this.loginLoading = true
                    this.form.rememberMe = this.rememberMe
                    this.form.recaptchaToken = await this.getRecaptchaToken()

                    const response = await this.$store.dispatch(
                        'auth/login',
                        this.form
                    )
                    if (!response) {
                        this.resetRecaptcha()
                    }
                    this.loginLoading = false
                } catch (error) {
                    this.loginLoading = false
                }
            }
            this.loading = false
        },
        onRegisterNow() {
            if (this.loginPage) {
                this.$router.push('/register')
            } else {
                this.toggle()
            }
        },
        onClose() {
            if (this.loginPage) {
                this.$router.push('/')
            } else {
                this.$emit('input', false)
            }
        },
        changeLoginType(type) {
            this.loginType = type || this.loginType
            if (this.loginType === 'username') {
                if (!this.form.player_id) {
                    const username = this.$cookie.get('un')
                    if (!username) {
                        return
                    }
                    this.form.player_id = username
                }
            } else if (this.loginType === 'phone') {
                if (!this.form.phone) {
                    const phoneNumber = this.$cookie.get('pn')
                    if (!phoneNumber) {
                        return
                    }
                    this.form.phone = phoneNumber
                }
            }
        },
    },
}
